.formGroup {
  margin-top: 18px;
}

.shorten {
  width: 100%;
}

@media (width >= 569px) {
  .shorten {
    max-width: 496px;
  }
}
