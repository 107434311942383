.form {
  padding: 0px 18px 18px 18px;
}

.loginFormWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: calc(100dvh - 90px - 30px);
}
@media (max-width: 768px) {
  .loginFormWrapper {
    min-height: calc(100dvh - 65px - 30px);
  }
}