.hiddenPlaceholder {
  display: none;
}

.selectWrapper {
  height: 100%;
  overflow: hidden;
}

.selectWrapper select::-ms-expand {
  display: none;
}

.select {
  color: #222;
  appearance: none;
  text-overflow: ellipsis;
  white-space: nowrap;
  background: none;
  border: none;
  outline: none;
  flex: 1;
  width: 100%;
  height: 100%;
  padding-right: 45px;
  font-family: proxima-nova, Helvetica Neue, helvetica, arial, "sans-serif";
  font-size: 16px;
  font-weight: 300;
  transition: padding-top .1s;
  overflow: hidden;
  box-shadow: inset 0 0 0 30px #fff !important;
}

.select:-ms-expand {
  display: none;
}

.select:disabled {
  cursor: not-allowed;
  box-shadow: inset 0 0 0 30px #ddd !important;
}

.withFakeNativePlaceholder {
  color: #666;
}

.selectAnimatedPlaceholderPadding {
  padding-top: 15px;
}

.basicSelectArrow {
  pointer-events: none;
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
}

.paddingLeftMedium {
  padding-left: 18px;
}

.paddingLeftSmall {
  padding-left: 9px;
}
