.messageBanner {
  justify-content: center;
  width: 100%;
  min-height: 45px;
  display: flex;
}

.success {
  background-color: #66a559;
}

.error {
  background-color: #c00;
}

.messageIcon {
  fill: #fff;
  flex: none;
  margin: 9px;
}

.failIcon {
  width: 27px;
  height: 27px;
}

.checkmarkIcon {
  width: 20px;
  height: 27px;
}

.message {
  color: #fff;
  flex: initial;
  margin: 12px 0;
}
