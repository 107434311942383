:local .well {
  font-family: "proxima-nova", "Helvetica Neue", "helvetica", "arial", "sans-serif";
  font-weight: 300;
  background: #fff;
  min-height: 20px;
  box-shadow: 0 2px 1px 0 rgba(0, 0, 0, 0.15);
}
:local .padding {
  padding: 19px;
}
:local .margin {
  margin-top: 9px;
  margin-bottom: 20px;
}
:local .label {
  font-family: "Cardinal Classic Short", "Georgia", "serif";
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 1.4;
  letter-spacing: -0.5px;
}