.eyeIcon {
  height: 20px;
  width: 20px;
}

.eyeIconCrossedOut {
  color: #666;
}

.eyeIconButton {
  display: flex;
  align-items: center;
}

.eyeIconWrapper {
  position: relative;
}

.eyeIconWrapperCrossedOut::before {
  content: "";
  display: block;
  height: 24px;
  width: 1px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) rotate(45deg);
  background-color: #666;
}