.verticalSelectionContainer {
  display: flex;
}

.verticalSelection {
  flex: auto;
}

.fieldLabel {
  font-family: proxima-nova, Helvetica Neue, helvetica, arial, "sans-serif";
  font-weight: 600;
}

.tooltip {
  flex: initial;
  width: 16px;
  height: 16px;
  margin: 40px 0 0 9px;
  position: relative;
}

@media (width <= 568px) {
  .tooltip {
    display: none;
  }
}

.questionMark {
  fill: #436b93;
  width: 16px;
  height: 16px;
  transform: translateY(10%);
}

.mobileHint {
  display: none;
}

@media (width <= 768px) {
  .mobileHint {
    color: #888;
    margin-top: 18px;
    font-family: proxima-nova, Helvetica Neue, helvetica, arial, "sans-serif";
    font-weight: 300;
    display: block;
  }
}
