.troubleshoot {
  background-color: #f3f3f3;
  margin-top: 27px;
  padding: 18px;
}

.troubleshootTitle {
  letter-spacing: -.5px;
  font-family: Cardinal Classic Short, Georgia, "serif";
  font-size: 16px;
  font-style: normal;
  font-weight: normal;
  line-height: 1.4;
}

.emailContainer {
  width: 72px;
  height: 72px;
  margin: 36px auto 0;
}

.circleStroke {
  stroke: #888;
}

.inquiryIcon {
  fill: #888;
}

.confirmationContent {
  max-width: 468px;
  margin: 0 auto 36px;
}
