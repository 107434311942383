.hiddenPlaceholder {
  display: none;
}

.selectWrapper {
  height: 100%;
  overflow: hidden;
}
.selectWrapper select::-ms-expand {
  display: none;
}

.select {
  box-shadow: 0 0 0 30px #fff inset !important;
  background: none;
  border: none;
  font-family: "proxima-nova", "Helvetica Neue", "helvetica", "arial", "sans-serif";
  font-size: 16px;
  font-weight: 300;
  color: #222;
  width: 100%;
  outline: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  flex: 1;
  height: 100%;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  transition: padding-top 0.1s ease;
  padding-right: 45px;
}
.select:-ms-expand {
  display: none;
}
.select:disabled {
  cursor: not-allowed;
  box-shadow: 0 0 0 30px #ddd inset !important;
}

.withFakeNativePlaceholder {
  color: #666;
}

.selectAnimatedPlaceholderPadding {
  padding-top: 15px;
}

.basicSelectArrow {
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  pointer-events: none;
}

.paddingLeftMedium {
  padding-left: 18px;
}

.paddingLeftSmall {
  padding-left: 9px;
}