:local .wrapper {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  position: relative;
}
:local .clickable {
  cursor: pointer;
}
:local .borderCircle {
  fill: none;
  stroke: currentColor;
  position: absolute;
  left: 0;
  top: 0;
}