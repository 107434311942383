.eyeIcon {
  width: 20px;
  height: 20px;
}

.eyeIconCrossedOut {
  color: #666;
}

.eyeIconButton {
  align-items: center;
  display: flex;
}

.eyeIconWrapper {
  position: relative;
}

.eyeIconWrapperCrossedOut:before {
  content: "";
  background-color: #666;
  width: 1px;
  height: 24px;
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%)rotate(45deg);
}
