.disclaimerWell {
  width: 100%;
  margin: 20px 0;
}

@media (width >= 769px) {
  .disclaimerWell {
    max-width: 350px;
    margin: 20px;
  }
}

.disclaimerContainer {
  justify-content: flex-end;
  width: 100%;
  display: flex;
}

.disclaimerContentContainer {
  justify-content: flex-end;
  display: flex;
}

.disclaimerCloseButton {
  flex: none;
  width: 15px;
  margin: -5px 0 5px 5px;
}
