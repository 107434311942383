:global #body {
  padding: 0;
}
:global body {
  background-color: #fbfbfb;
  font-family: "proxima-nova", "Helvetica Neue", "helvetica", "arial", "sans-serif";
  font-weight: 300;
}
@media (max-width: 768px) {
  :global #outer-row {
    padding-top: 65px;
  }
}