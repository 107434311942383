:local .well {
  background: #fff;
  min-height: 20px;
  font-family: proxima-nova, Helvetica Neue, helvetica, arial, "sans-serif";
  font-weight: 300;
  box-shadow: 0 2px 1px #00000026;
}

:local .padding {
  padding: 19px;
}

:local .margin {
  margin-top: 9px;
  margin-bottom: 20px;
}

:local .label {
  letter-spacing: -.5px;
  font-family: Cardinal Classic Short, Georgia, "serif";
  font-size: 24px;
  font-style: normal;
  font-weight: normal;
  line-height: 1.4;
}
