.verticalSelectionContainer {
  display: flex;
}

.verticalSelection {
  flex: auto;
}

.fieldLabel {
  font-family: "proxima-nova", "Helvetica Neue", "helvetica", "arial", "sans-serif";
  font-weight: 600;
}

.tooltip {
  width: 16px;
  height: 16px;
  position: relative;
  margin: 40px 0 0 9px;
  flex: initial;
}
@media (max-width: 568px) {
  .tooltip {
    display: none;
  }
}

.questionMark {
  height: 16px;
  width: 16px;
  transform: translateY(10%);
  fill: #436b93;
}

.mobileHint {
  display: none;
}
@media (max-width: 768px) {
  .mobileHint {
    display: block;
    margin-top: 18px;
    font-family: "proxima-nova", "Helvetica Neue", "helvetica", "arial", "sans-serif";
    font-weight: 300;
    color: #888;
  }
}