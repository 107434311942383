.formGroup {
  margin-top: 18px;
}

.shorten {
  width: 100%;
}
@media (min-width: 569px) {
  .shorten {
    max-width: 496px;
  }
}