.disclaimerWell {
  width: 100%;
  margin: 20px 0px;
}
@media (min-width: 769px) {
  .disclaimerWell {
    max-width: 350px;
    margin: 20px;
  }
}

.disclaimerContainer {
  display: flex;
  justify-content: flex-end;
  width: 100%;
}

.disclaimerContentContainer {
  display: flex;
  justify-content: flex-end;
}

.disclaimerCloseButton {
  flex: none;
  width: 15px;
  margin: -5px 0px 5px 5px;
}