.header {
  margin-bottom: 27px;
  font-size: 16px;
  font-weight: 300;
}

.message, .inputWrapper {
  margin-bottom: 18px;
}

.loginAgainCta {
  text-align: right;
}
