.form {
  padding: 0 18px 18px;
}

.loginFormWrapper {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: calc(100dvh - 120px);
  display: flex;
}

@media (width <= 768px) {
  .loginFormWrapper {
    min-height: calc(100dvh - 95px);
  }
}
