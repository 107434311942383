.troubleshoot {
  background-color: #f3f3f3;
  padding: 18px;
  margin-top: 27px;
}

.troubleshootTitle {
  font-family: "Cardinal Classic Short", "Georgia", "serif";
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 1.4;
  letter-spacing: -0.5px;
}

.emailContainer {
  height: 72px;
  width: 72px;
  margin: 36px auto 0 auto;
}

.circleStroke {
  stroke: #888;
}

.inquiryIcon {
  fill: #888;
}

.confirmationContent {
  margin: 0 auto 36px auto;
  max-width: 468px;
}