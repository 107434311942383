:local .wrapper {
  justify-content: center;
  align-items: center;
  display: inline-flex;
  position: relative;
}

:local .clickable {
  cursor: pointer;
}

:local .borderCircle {
  fill: none;
  stroke: currentColor;
  position: absolute;
  top: 0;
  left: 0;
}
